<template v-if="productionForm">
  <div>
    <div v-for="animalGroup in animalGroups" :key="animalGroup.id">
      <productionFormTable
        :productionForm="productionForm"
        :animalGroup="animalGroup"
        :periodeMonth="periodeMonth"
        :loadPreviousMonths="loadPreviousMonths"
        :term="term"
        :productionFormFindQuery="productionFormFindQuery"
        :productionFormsArrayLength="productionFormsArrayLength"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import productionFormTable from './productionFormTable'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'productionFormEdit',
  props: {
    productionForm: Object,
    animalType: Number,
    periodeMonth: Number,
    loadPreviousMonths: String,
    term: Array,
    productionFormFindQuery: Object,
    productionFormsArrayLength: Number
  },
  components: {
    productionFormTable: productionFormTable
  },
  data() {
    return {
      dialog: false,
      loading: false,
      animalGroups: [],
      animalGroupRows: []
    }
  },
  methods: {
    async getAnimalGroups() {
      if (!this.productionForm) return
      try {
        const result = await this.axios.get(
          `${apiURL}/productionForms/${this.productionForm.id}/animalGroups`,
          { params: { animalTypeId: this.animalType } },
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.animalGroups = await result.data
      } catch (e) {
        showError(e)
      }
    }
  },
  watch: {
    productionForm: {
      handler() {
        this.getAnimalGroups()
      }
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      await this.getAnimalGroups()
    })
  }
})
</script>
