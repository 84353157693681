<template class="productionForm">
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-html="$t('history')" />
        </v-card-title>
        <v-card-text>
          <rowDetails :productionForm="productionForm" :productionFormRow="row" :correction="true" @patch-quantity="patchQuantityFromDetails"></rowDetails>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="closeDetails()">
            <span v-html="$t('close')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col sm="11">
        <p>{{ animalGroup['animalType' + this.$getUpLangKey()] }}</p>
      </v-col>
      <v-col class="align-right" sm="1">
        <v-icon @click="toggle()" size="large">{{ chevronClass }}</v-icon>
      </v-col>
    </v-row>
    <v-data-table-server
      ref="productionFormAnimalGroupTable"
      class="no-margin-top custom-table-styling"
      :headers="animalGroupRowFields()"
      :fields="animalGroupRowFields()"
      :items="animalGroupRows"
      :loading="loading"
      density="compact"
      :items-length="totalElements"
      @update:options="sort_n_pageing"
      v-model:options="page_n_sort"
      :items-per-page-options="$itemsPerPageOptions"
    >
      <template v-slot:[`item.agisId`]="{ item }">
        <template v-if="$privileges.has({ path: '/farms', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.farmId, tab: 'overview' }
            }"
            tabindex="-1"
          >
            {{ item.agisId ? item.agisId : '-' }}
          </router-link>
        </template>
        <template v-else>
          {{ item.agisId ? item.agisId : '-' }}
        </template>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        <template v-if="item.status === 'CLOSED' || item.disabled">
          <div class="link" color="primary" @click="openDetails(item)">
            {{ item && item.quantity ? $formatNumber(item.quantity, 1) : '-' }}
          </div>
        </template>

        <template v-else>
          <vNumberField
            :name="'item_id_' + item.id"
            id="item_quantity"
            class="nomessage right-aligned-input"
            :rules="[$rules.isNumeric]"
            :disabled="!item.editable"
            filled
            v-model="item.quantity"
            @change="patchProductionFormRow(item)"
            density="compact"
            :precision="1"
            :loading="item.loading"
            autocomplete="off"
            @keyup.enter="$goNext($event.target)"
            @keydown.enter.prevent="patchProductionFormRow(item)"
          ></vNumberField>
        </template>
      </template>

      <template v-slot:[`item.quantity2`]="{ item }">
        <span :class="(item.quantity1 / 100) * 50 > item.quantity2 || (item.quantity2 / 100) * 50 > item.quantity1 ? 'dbm-red' : undefined">
          {{ item && item.quantity2 ? $formatNumber(item.quantity2, 1) : undefined }}</span
        >
      </template>

      <template v-for="index in [3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" v-slot:[`item.quantity`+index]="{ item }">
        <span v-bind:key="'slotindex' + index">{{ item && item['quantity' + index] ? $formatNumber(item['quantity' + index], 1) : undefined }}</span>
      </template>

      <template v-slot:[`body.append`]="{ headers }">
        <tr>
          <td v-for="(header, i) in headers[0]" :key="i">
            <div v-if="header.value === 'agisId'"><span v-html="$t('Total')" />: {{ totalElements }}</div>
            <div v-if="header.value === 'quantity'" class="text-end">
              {{ animalGroupTotalFromPatchResult ? $formatNumber(animalGroupTotalFromPatchResult, 1) : $formatNumber(animalGroup[header.value], 1) }}
            </div>
            <div v-if="header.value !== 'agisId' && header.value !== 'quantity'" class="text-end">
              {{ animalGroup && animalGroup[header.value] ? $formatNumber(animalGroup[header.value], 1) : undefined }}
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:[`item.farmName`]="{ item }">
        <template v-if="$privileges.has({ path: '/legalEntities', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_legal_entities_edit',
              params: { id: item.legalEntityId, tab: 'overview' }
            }"
            tabindex="-1"
          >
            {{ item.farmName ? item.farmName : '-' }}
          </router-link>
        </template>
        <template v-else>
          {{ item.farmName ? item.farmName : '-' }}
        </template>
      </template>

      <template v-slot:[`item.sapCleared`]="{ item }">
        <template
          v-if="
            $privileges.has({
              path: '/subsidyInvoices/production',
              permission: 'read'
            })
          "
        >
          <router-link
            :to="{
              name: 'subsidy_invoices_production',
              query: { sapInvoiceIdent: item.sapDocNo, size: 50 }
            }"
            tabindex="-1"
          >
            {{ item.sapCleared ? $moment(item.sapCleared).format('DD.MM.YYYY') : '-' }}
          </router-link>
        </template>
        <template v-else>
          {{ item.sapCleared ? $moment(item.sapCleared).format('DD.MM.YYYY') : '-' }}
        </template>
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <a color="primary" @click="openDetails(item)">
          <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
        </a>
      </template>

      <template v-slot:no-data>{{ undefined }}</template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import { apiURL } from '@/main'
import { mapGetters, mapActions } from 'vuex'
import { backendService, VuetifyDTOptions } from '@/services/BackendService'
import { Term } from '@/services/term'
import { showError } from '@/services/axios'
import vNumberField from '../../../components/v-number-field.vue'
import details from './details'
import { useProductionFormStatusStore } from '@/store/enums/ProductionFormStatusStore'

const defaultOptions = {
  page: '0',
  size: '50',
  sort: ['farmDairyNo', 'zip'],
  sortDesc: [false, false]
}
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'productionFormTable',
  components: {
    vNumberField: vNumberField,
    rowDetails: details
  },
  props: {
    productionForm: Object,
    animalGroup: Object,
    periodeMonth: Number,
    loadPreviousMonths: String,
    term: Array,
    productionFormFindQuery: Object,
    productionFormsArrayLength: Number
  },
  data() {
    return {
      animalGroupRows: [],
      options: defaultOptions,
      loading: false,
      totalElements: 0,
      page_n_sort: new VuetifyDTOptions(),
      isExpanded: false,
      chevronClass: 'mdi-chevron-down',
      dialog: false,
      row: {},
      animalGroupTotalFromPatchResult: undefined
    }
  },
  computed: {
    productionFormStatus() {
      return useProductionFormStatusStore().productionFormStatus
    }
  },
  methods: {
    async sort_n_pageing(e) {
      if (this.animalGroupRows.length === 0) return true

      this.options = {
        ...backendService.toJavaPage(e)
      }
    },
    animalGroupRowFields() {
      const fields = [
        {
          value: 'edit',
          sortable: false,
          width: '2%'
        },
        {
          title: this.$t('production_form_table_agis_id'),
          value: 'agisId',
          align: 'left',
          sortable: true
        },
        {
          title: this.$t('production_form_table_info'),
          value: 'info',
          sortable: false
        },
        {
          title: this.$t('production_form_table_farm_dairy_no'),
          value: 'farmDairyNo',
          align: 'left',
          sortable: true
        },
        {
          title: this.$t('production_form_table_name1'),
          value: 'farmName',
          sortable: false
        },
        {
          title: this.$t('production_form_table_name2'),
          value: 'farmFirstName',
          sortable: false
        },
        {
          title: this.$t('production_form_table_zip'),
          value: 'zip',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('production_form_table_locality'),
          value: 'locality',
          sortable: false
        }
      ]

      let numberOfMonths = 12
      if ('PREVIOUS' === this.loadPreviousMonths) {
        numberOfMonths = 2
      } else if ('START_OF_THE_YEAR' === this.loadPreviousMonths) {
        numberOfMonths = this.periodeMonth
      } else if ('TWELVE_MONTHS' === this.loadPreviousMonths) {
        numberOfMonths = 12
      }

      const current = new Date(2000, this.periodeMonth - 1 - numberOfMonths, 1)
      for (let i = numberOfMonths; i > 0; i--) {
        current.setMonth(current.getMonth() + 1)
        const translation = Intl.DateTimeFormat(this.$route.params.lang, {
          month: 'short'
        }).format(current)
        const quantityField = {
          title: translation,
          value: i === 1 ? `quantity` : `quantity${i}`,
          sortable: false,
          align: 'end'
        }
        fields.push(quantityField)
      }
      fields.push({
        title: this.$t('production_form_table_status'),
        value: 'statusName',
        sortable: false
      })
      if (
        this.$privileges.has({
          path: '/productionForms',
          permission: 'allDomains'
        })
      ) {
        fields.push({
          title: this.$t('production_form_table_number'),
          value: 'versionNo',
          align: 'left',
          sortable: false
        })
        fields.push({
          title: this.$t('production_form_run_date'),
          value: 'sapCleared',
          sortable: false
        })
      }
      return fields
    },
    async toggle() {
      if (this.isExpanded) {
        this.animalGroupRows = []
        this.isExpanded = false
        this.chevronClass = 'mdi-chevron-down'
      } else {
        await this.getAnimalGroupRows()
        this.isExpanded = true
        this.chevronClass = 'mdi-chevron-up'
      }
    },
    async getAnimalGroupRows(paginationOptions) {
      if (!paginationOptions) paginationOptions = this.options
      this.loading = true
      try {
        const result = await this.axios.get(
          `${apiURL}/productionForms/${this.productionForm.id}/${this.animalGroup.id}/rows`,
          {
            params: {
              loadPreviousMonths: this.loadPreviousMonths,
              animalTypeId: this.animalGroup.id,
              ...this.productionFormFindQuery,
              ...this.options,
              term: Term.stringify(this.term)
            }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.totalElements = await result.data.totalElements
        this.animalGroupRows = result.data.content
        this.animalGroupRows.forEach((row) => {
          if (row.status) {
            row.editable = this.productionFormStatus.find((status) => status.name === row.status).editable
            row.disabled = row.editable && this.productionForm.dairyInactive
            row.statusName = this.productionFormStatus.find((status) => status.name === row.status)[this.$getLangKey()]
          } else {
            row.editable = false
            row.statusName = ''
          }
        })
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async patchProductionFormRow(item) {
      item.loading = true
      try {
        const result = await this.axios.patch(
          `/api/productionFormRows/${item.id}`,
          {
            quantity: item.quantity
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.animalGroupTotalFromPatchResult = result.data.animalGroupTotalQuantities.find((x) => x.animalTypeId === this.animalGroup.id).quantity
          item.statusName = this.productionFormStatus.find((status) => status.name === result.data.status)[this.$getLangKey()]
          if (result.data.productionFormId !== null) this.$parent.$emit('reload-production-form', result.data)
        }
      } catch (e) {
        showError(e)
      } finally {
        item.loading = false //in case of success item is overridden by the ajax call
      }
    },
    openDetails(item) {
      this.row = item
      this.dialog = true
    },
    closeDetails() {
      this.dialog = false
    },
    async patchQuantityFromDetails(row) {
      this.dialog = false
      await this.patchProductionFormRow(row)
      this.getAnimalGroupRows()
    }
  },
  watch: {
    options: {
      handler(newPaginationOptions, oldPaginationOptions) {
        if (
          (newPaginationOptions.page !== oldPaginationOptions.page ||
            newPaginationOptions.size !== oldPaginationOptions.size ||
            newPaginationOptions.sort !== oldPaginationOptions.sort) &&
          !_.isEmpty(newPaginationOptions) &&
          this.isExpanded
        ) {
          this.getAnimalGroupRows(newPaginationOptions)
        }
      },
      deep: true
    },
    'productionForm.statusInfo': {
      async handler(to, from) {
        // TODO from is the same object unless ...https://vuejs.org/guide/essentials/watchers.html#deep-watchers
        if (to.isExpanded && !from.isExpanded) {
          await this.getAnimalGroupRows()
        }
      },
      deep: true
    }
  },
  beforeMount() {
    useProductionFormStatusStore().fetchProductionFormStatus()
  },
  async mounted() {
    if (this.productionFormsArrayLength === 1) {
      this.toggle()
    }
  }
})
</script>
